import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import MobileBrowserPreview from "../MobileBrowserPreview"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/Manual/";

const portfolioImages = [
  {url:images+"Discovery Readout_Redacted_Page_01.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_02.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_03.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_04.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_05.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_06.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_07.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_08.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_09.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_10.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_11.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_12.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_13.jpg", caption:false},
  {url:images+"Discovery Readout_Redacted_Page_14.jpg", caption:false},
];

class Manual extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}

        <div className="white-background">
          <section className="container">
            <h1>Manual Review Process & Systems</h1>
            <div className="date-container">
              <div className="date">2019</div>
              <div><Button className="jump-button" type="text" onClick={e => this.scrollToDesigns()}>Skip to the Readout Deck</Button></div>
            </div>
          </section>
        </div>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Brief</h2>
            <div className="brief-content">
              <h4>Company</h4>
              <p><strong>The Home Depot</strong></p>

              <h4>Role</h4>
              <p><strong>Sr. User Experience Designer</strong></p>

              <h4>Experience</h4>
              <p><strong>Manual Review:</strong> A process for consultative sales to get customers the best possible price on very large jobs.</p>

              <h4>Summary</h4>
              <p>Manual Review consists of a team, processes, and legacy systems to support the Volume Pricing Program (VPP). Leadership wanted to build a new system to support the team and process. Their goal was to unlock additional value through integration with other systems. The team did not have a clear understanding of the Manual Review process. I conducted research to document current systems & processes. I also identified opportunities for improvement. This research was used to inform prioritization of the initiative and to help define scope. </p>
            </div>
          </section>
        </section>

        {/* BODY */}
        <section id="designs" className="designs blue-background"  ref={node => this.designs.current = node}>
          <h2>Readout</h2>
          <p>The following redacted presentation shares my research findings and recommendations.</p>
          <BrowserPreview scrollable={false} direction="horizontal" images={portfolioImages}/>
        </section>


      </div>
    );
  }
}

export default withRouter(Manual);

import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/Order/";

const portfolioImages = [
  {url:images+"1.jpg", caption:"The Programs List screen allows users to easily find programs within the system. "}
];

class Order extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}
        <section className="container">
          <h1>Order Details Enhancement</h1>
          <div className="date-container">
            <div className="date">Case Study: 2018</div>
            <div><Button className="jump-button" type="text" onClick={e => this.scrollToDesigns()}>Skip to the Final Designs</Button></div>
          </div>
          <Image className="hero-image" src={images+"Hero.jpg"} preview={false} width="100%"/>
          <section className="brief">
            <h2 className="brief-title">Brief</h2>
            <div className="brief-content">
              <Row gutter={24}>
                <Col span={24} md={12}>
                  <h4>Company</h4>
                  <p><strong>The Home Depot</strong></p>
                </Col>
                <Col span={24} md={12}>
                  <h4>Role</h4>
                  <p><strong>Sr. UX Designer</strong></p>
                </Col>
              </Row>
              <h4>Product</h4>
              <p><strong>Price Engine:</strong> A tool used to set discounts and condition-based price points for millions of Home Depot products.</p>

              <h4>Users</h4>
              <p><strong>Pricing Execution Team:</strong> A team of 3 contractors and a manager who receive requests for changes to discounts and pricing programs. They are expected to execute the changes and use Price Engine to complete their task.</p>
              <Divider />
              <h4>Background</h4>
              <p>The Home Depot’s Pricing Team wanted to pilot a new pricing program targeting Pro customers. The initial program was to test 20k price points across 3 test markets. Each of these price points would need to be manually entered into Price Engine. This process would take about 25 days for the Pricing Execution Team to complete with 3 people—an unreasonable amount of time to sustain anything more than a pilot.</p>
              <h4>Activities</h4>
              <p>I sought to find a way to enable our users to more quickly and easily manage these programs. I conducted research, experimented with new experience concepts, facilitated prioritization & roadmap discussions, and coded & tested interactive prototypes with users. I also onboarded & mentored a UX Apprentice during this time, Trevor Richards, who contributed to this initiative.</p>
              <h4>Impact</h4>
              <p>Within the first quarter of the effort we were able to reduce the time to enter 20k price points from 25 days with 3 people to less than 1 hour with 1 person.</p>


            </div>
          </section>
        </section>

        {/* BODY */}
        <section className="white-background">
          <div className="container">

            <div className="small-p">
              <h2>My Process</h2>
              <h3>Research &amp; Baseline</h3>
            </div>
            <figure className="side-image">
              <Image src={images+"CardSort.jpg"} preview={true}/>
              <figcaption>Card sort activity with the Pricing Execution Team users to better understand data relationships.</figcaption>
            </figure>

            <div className="small-p">
              <p>I needed to understand more about the pricing programs. To do so, I met with the pricing analysts to understand how the programs were structured. Since the users on the execution team were new to me, I also conducted interviews (contextual inquiries) with them to understand their role and day-to-day responsibilities.</p>
            </div>

            <Quote>
            I discovered that another user group had similar needs for a quarterly maintenance process.
            </Quote>

            <div className="small-p">
              <p>A card sort activity with the users was helped me to better understand the relationships between price point properties within a program. I also interviewed other Price Engine users to understand if they had any similar needs for managing large programs. This was to ensure our efforts were not siloed. I discovered that another user group had similar needs for a quarterly maintenance process.</p>
              <p>As time was of the essence for this initiative, I conducted a time study. This helped set a baseline to better understand how long it takes to create a single price point with the current process. This informed our goal of reducing the time it takes to set up a large program to less than one hour.</p>
            </div>



            <div className="small-p">
              <h3>Synthesize Findings &amp; Make Recommendations</h3>
              <p>Based on this research, I prepared artifacts and a readout. Journey maps for both creation and maintenance activities helped communicate the pain-points in the current process.</p>
            </div>
            <figure>
              <Image src={images+"Deck.jpg"} preview={false} width="100%"/>
              <figcaption>Sample slides from the readout. The deck was a collaboration with Trevor Richards, UX Apprentice.</figcaption>
            </figure>
            <Row gutter={[50,0]}>
              <Col span={24} md={12}>
                <p>Some key findings presented to the product team and leadership:</p>
                <ul>
                  <li>Users receive an Excel intake file with the price point data, which they manipulate to make it easier to enter into Price Engine</li>
                  <li>The majority of fields they enter do not change for all the price points within a program</li>
                  <li>Programs have customer tiers with varying price points per tier</li>
                  <li>Each price point must be manually entered, which is a highly error-prone process</li>
                  <li>Maintenance of basic properties, like start/end date is extremely time consuming and requires users to touch each price point</li>
                  <li>Some price point entries that were manipulated for easier entry must be reverse-engineered to properly modify them in the future</li>
                </ul>
              </Col>
              <Col span={24} md={12}>
                <p>The recommendations included:</p>
                <ul>
                  <li>Allow users to enter multiple discounts at once</li>
                  <li>Support the data model users are used to working with</li>
                  <li>Support mass editing</li>
                  <li>Allow users to centrally control settings for all discounts within a program structure</li>
                </ul>
              </Col>
            </Row>

            <div className="small-p">
              <h3>Concept Experiments</h3>
            </div>
            <figure className="side-image">
              <Image src={images+"OldProto.png"} preview={true}/>
              <figcaption>An experimental concept screen allowing users to edit the tier settings for a single market</figcaption>
            </figure>

            <Quote>
            I conducted a scenario-based user test. It failed miserably…which was great!
            </Quote>

            <div className="small-p">
              <p>There were many possible solutions to address the user needs. So, I created a prototype with an experimental concept based on multiple hypotheses. Primarily, I thought users would want to manually triangulate markets, customer tiers, and purchase thresholds to define price points.</p>
              <p>I conducted a scenario-based user test. It failed miserably…which was great! I quickly learned users did not want to manually triangulate in that way. They wanted the system to manage that complexity for them. Through that failure I learned a lot. Primarily, that the new program parent structure was a success. So, I iterated on this experiment and continued to validate with users.</p>
              <p>The resulting experience centered around creating a program and attaching price points en masse to it. This would be done by transposing data from Excel to Price Engine and allowing the system to do most of the work. </p>
            </div>

            <div className="small-p">
              <h3>Prioritize &amp; Roadmap</h3>
              <p>My research and experiment revealed that a large amount of drastic changes would be needed to support large pricing programs throughout their lifecycle. We needed to deliver value quickly and to define our MVP. So, I brought the team together for a workshop. We used this time together to level-set on knowledge, including user & business needs. We decided to go after quick-win functionality (high impact, low effort).</p>
            </div>

            <div className="small-p">
              <h3>Communicate a Vision &amp; Actionable Roadmap</h3>
              <p>The journey to support large programs was to be a long one. But by prioritizing effectively we knew we could unlock value quickly. To help the team understand the direction we were going in, I created a visiontype (vision prototype). This interactive prototype depicted the future state of Price Engine once all the proposed changes were implemented.</p>
            </div>
            <Row gutter={[50,50]}>
              <Col span={24} md={8}>
                <figure>
                  <Image src={images+"OldProgram.png"} preview={true}/>
                  <figcaption>A high-fidelity visiontype used to help explain how a program would function within Price Engine.</figcaption>
                </figure>
              </Col>
              <Col span={24} md={8}>
                <figure>
                  <Image src={images+"OldS1.png"} preview={true}/>
                  <figcaption>A screen enabling users to transpose their Excel data into Price Engine using copy/paste (an upload function was out of scope for this particular application). I tried to replicate the tabular view and interactivity of Excel for familiarity. </figcaption>
                </figure>
              </Col>
              <Col span={24} md={8}>
                <figure>
                  <Image src={images+"OldS2.png"} preview={true}/>
                  <figcaption>A review screen providing a summary of the discount settings. This screen also allows users to map the entered tier names to the known tier names within Price Engine.</figcaption>
                </figure>
              </Col>
            </Row>


            <Quote>
              This visiontype allowed us to better communicate the functionality we were working on. It also helped make things more tangible for both the product team and leadership.
            </Quote>
            <div className="small-p">
              <p>This visiontype allowed us to better communicate the functionality we were working on. It also helped make things more tangible for both the product team and leadership. While it does not exactly mirror the final product, it contains the majority of the concepts we ended up executing.</p>
              <p>To accompany the visiontype, I collaborated with my product manager on an actionable roadmap. This roadmap broke the work down into phases. It enabled us to remain focused on the short-term while keeping the long-term in mind. It was a powerful tool to answer the question, “what’s next.”</p>
            </div>

            <div className="small-p">
              <h3>Design &amp; Test</h3>
            </div>

            <figure className="side-image">
              <Image src={images+"Validations.png"} preview={true}/>
              <figcaption>An Excel document containing all of the validations within the experience. I created this to share with the engineers as an efficient way of documenting the validation scenarios. </figcaption>
            </figure>
            <figure className="side-image">
              <Image src={images+"OldSingle.png"} preview={true}/>
              <figcaption>The first iteration (the MVP) enabled users to create a program and associate price points (discounts) to it. The discount entry reduced the number of required fields from 14 to 3, saving valuable time. The global program settings (not shown here) are managed at a program level, making it easy to make sweeping changes across thousands of price points at a time.</figcaption>
            </figure>

            <div className="small-p">
              <p>The early experiments and visiontype provided a conceptual foundation for what we needed to work on. But the devil is in the details. I needed to create real designs that would fit within the current phase of our roadmap and could be iterated on. I also needed to test these designs with real data to effectively gauge users’ reactions to the system’s numerous alerts and data-driven insights.</p>
              <p>To support effective testing and eventual handoff to the engineering team, I coded the prototype with React and our design system. I decided to go this route to reduce the risk of testing with static prototypes. This prototype allowed for user data entry and simulated some complex validations.</p>
            </div>

            <div className="small-p">
              <h3>Deliver & Iterate</h3>
              <p>The visiontype and roadmap guided us through our journey to delivering value. Our first release allowed users to create a program and associate individual price points to it. This cut the time to enter a single price point in half. The functionality allowed the team to supported some larger pilot programs. We continued to iterate on the design, pivoting and iterating where required.</p>
              <p>The second release enabled users to transpose price points directly from their Excel intake form and associate them to a program. This reduced the time to create a large program from 25 days with 3 people to less than 1 hour with 1 person. It was a huge win.</p>
              <p>But we didn’t stop there. We needed to account for additional scenarios—including the one for the other Price Engine user that had a similar use case. We continue to enhance the experience based on evolving business needs and feedback from our users.</p>
            </div>



          </div>
        </section>

        <section id="designs" className="designs blue-background"  ref={node => this.designs.current = node}>
          <div className="container">
              <h2>Designs</h2>
              <p>The following designs are screenshots of the interactive prototype. Much of the front-end code was adopted by the engineers for use in production.</p>
          </div>

          <div className="container">
            <BrowserPreview scrollable={true} images={portfolioImages}/>
          </div>

        </section>

      </div>
    );
  }
}

export default withRouter(Order);

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, withRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const routing = (
  <HashRouter>
      <Switch>
        <Route path='/' component={App} />
      </Switch>
  </HashRouter>
)
setTimeout(()=>{
    ReactDOM.render(routing, document.getElementById('root'))
}, 200)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { Image, Space } from 'antd';
import { withRouter} from 'react-router-dom';
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import './App.css';

class MobileBrowserPreview extends React.Component {
  constructor(props) {
     super(props);
  }


  render(){
    return(
      <div className="mobile-browser-preview">
          <div className="images container">
          {this.props.images.map((image, index) => (
            <div key={index} className="image">
              <Image src={image.url} preview={false}/>
              <div key={index} className="caption">
                {image.caption}
              </div>
            </div>
          ))}
          </div>
      </div>
    );
  }
}

export default withRouter(MobileBrowserPreview);

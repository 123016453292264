import React from 'react';
import { withRouter} from 'react-router-dom';
import './App.css';

class Quote extends React.Component {
  render(){
    return(
      <div className="quote-container">
        <div className="quote-wrapper">
          <div className="quote">
          {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Quote);

import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/CommunityMarket/";

const portfolioImages = [
  {url:images+"Slide1.jpeg", caption:false},
  {url:images+"Slide2.jpeg", caption:false},
  {url:images+"Slide3.jpeg", caption:false},
  {url:images+"Slide4.jpeg", caption:false},
  {url:images+"Slide5.jpeg", caption:false},
  {url:images+"Slide6.jpeg", caption:false},
  {url:images+"Slide7.jpeg", caption:false},
  {url:images+"Slide8.jpeg", caption:false},
  {url:images+"Slide9.jpeg", caption:false},
  {url:images+"Slide10.jpeg", caption:false},
  {url:images+"Slide11.jpeg", caption:false},
  {url:images+"Slide12.jpeg", caption:false},
  {url:images+"Slide13.jpeg", caption:false},
  {url:images+"Slide14.jpeg", caption:false},
  {url:images+"Slide15.jpeg", caption:false},
  {url:images+"Slide16.jpeg", caption:false},
  {url:images+"Slide17.jpeg", caption:false},
  {url:images+"Slide18.jpeg", caption:false},
  {url:images+"Slide19.jpeg", caption:false},
  {url:images+"Slide20.jpeg", caption:false},
  {url:images+"Slide21.jpeg", caption:false},
  {url:images+"Slide22.jpeg", caption:false},
  {url:images+"Slide23.jpeg", caption:false},
  {url:images+"Slide24.jpeg", caption:false},
];

class CommunityMarket extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}
        <div className="white-background">
          <section className="container">
            <h1>Community Market Business Plan</h1>
            <div className="date-container">
              <div className="date">2020</div>
              <div><Button className="jump-button" type="text" onClick={e => this.scrollToDesigns()}>Skip to the Final Designs</Button></div>
            </div>
          </section>
          <Image className="hero-image" src={images+"Hero.jpg"} preview={false} width="100%"/>
        </div>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Context</h2>
            <div className="brief-content">
              <h4>Summary</h4>
              <p>I was part of a 5-person team conducting cutomer discovery &amp; business idea validation. This was the capstone project for the Georgia Institute of Technology's Management of Technology MBA program. </p>

              <h4>Value Proposition</h4>
              <p>Professionals living in centralized communities who don't have time to cook for themselves will buy prepared meals from our kiosk because they want <strong>quality food now.</strong></p>
            </div>
          </section>
        </section>

        {/* BODY */}
        <section className="white-background">
          <div className="container">
            <figure className="side-image">
              <Image src={images+"Grocer Mockup.jpg"} preview={true}/>
              <figcaption>A rough mockup of our original mini-market kiosk concept.</figcaption>
            </figure>
            <div className="small-p">
              <h2>Details</h2>
              <h3>Hypothesis</h3>
              <p>As part of my MBA program I worked with my teammates to establish a business plan. We began with an idea for a kiosk in apartment complexes containing last-minute grocery items. We believed that young professionals who like to cook would shop at our kiosk for their last minute needs.</p>
              <h3>Customer Discovery</h3>
              <p>We conducted over 200 customer interviews collectively to learn about potential customers. We discovered that last minute ingredient needs were not a big enough problem for our customers.</p>
              <p>But along the way, we learned more about their cooking and shopping habits. We also identified different customer architypes:</p>
            </div>
            <figure className="side-image">
              <Image src={images+"BusyBart.jpg"} preview={true}/>
              <figcaption>A hypothesized customer journey based on customer interview data for Busy Bart</figcaption>
            </figure>
            <div className="small-p">
              <ul>
                <li><strong>Busy Bart:</strong> no time to cook, but still want a fresh wholesome meal</li>
                <li><strong>Creative Carla:</strong> passionate about cooking, but waste a lot of time prepping</li>
                <li><strong>Social Stacey:</strong> usually eating out or with friends, so keeping a stocked fridge is wasteful</li>
                <li><strong>Lazy Larry:</strong> eat to survive and don’t enjoy cooking</li>
              </ul>
              <h3>Narrowing In</h3>
              <p>Based on our discovery, we thought the kiosk idea had merit. It’s what is inside the kiosk that we needed to refine. We heard that people wanted meals, but different architypes wanted different types of meals. We needed to narrow our focus. So, we decided to go after Busy Bart as our target customer. Busy Bart already had a pattern of ordering in and picking up meals. He is often in a bind for a good meal because of how busy he is with his career. Additionally, the Busy Barts we spoke to reported that they would frequently shop at our Kiosk. </p>
            </div>
            <figure className="side-image">
              <Image src={images+"Marketing.jpg"} preview={true}/>
              <figcaption>The marketing single-page site for a meal kit option. We tested this concept with customers.</figcaption>
            </figure>
            <div className="small-p">
              <h3>Defining the Meals</h3>
              <p>We needed to know what kind of meals our customers wanted. We envisioned three different options:</p>
              <ul>
                <li><strong>Meal Kits:</strong> fresh, prepared, and uncooked ingredients with a recipe</li>
                <li><strong>Mix & Match:</strong> fresh marinated meats and uncooked side dishes ready for you to cook your own meal</li>
                <li><strong>Ready-to-Cook Meals:</strong> trays of complete, uncooked meals ready to heat/bake and serve</li>
              </ul>
              <p>To better understand which option our target customers would want, we created three marketing website landing pages. The pages described, in detail, each of the three options. We then met with potential customers and received feedback on each of the proposed solutions. The ready-to-cook meals were the preferred option because of their speed. </p>
            </div>
            <figure className="side-image">
              <Image src={images+"Mapping.jpg"} preview={true}/>
              <figcaption>I mapped customer needs to potential product features. The color coding designates the part of the customer journey impacted.</figcaption>
            </figure>
            <div className="small-p">
              <h3>Partnership</h3>
              <p>Our kiosk solution was based in community environments, like apartment complexes. So, we spoke to apartment managers about this concept. We learned that they are willing to add such a kiosk as an amenity as long as there is no maintenance required from them. They would also want to ensure the customer support is very strong. They felt their reputation would be impacted by a negative experience with our kiosk. So, the execution would have to be flawless to maintain our partnership.</p>
              <h3>Logistics</h3>
              <p>In order to learn more about the logistics of running a kiosk, we spoke to a few experienced people. First was a food services distributor who maintains kiosks, coffee stations, and stadium food services. Then we spoke to a restauranteur to understand what it is like to run a food services operation. We also spoke to kiosk manufacturers about the existing technology for smart, self-serve kiosks. </p>
              <h3>Viability</h3>
              <p>We put together a stepped cost structure based on the number of projected kiosks over time. Based on our interview data and market research, we put together an estimate of the number of anticipated customers served weekly. We then calculated customer acquisition and estimated lifetime value. We determined that based on our research, the concept is both desirable and viable. Feasibility is where we need to conduct more research, as this is not an industry our team is very experienced in.</p>
              <h3>Conclusion</h3>
              <p>I very much enjoyed this project and am thrilled to have had such a wonderful team. I loved applying my customer-centric skillset to this project. I hope to take the skillset I learned through this program and apply it to a future entrepreneurial venture.</p>

            </div>
          </div>
        </section>

        <section id="designs" className="designs blue-background"  ref={node => this.designs.current = node}>
          <div className="container">
              <h2>Presentation</h2>
              <p>The intro video and deck used for our final presentation to potential investors.<br/>I was responsible for the video production and presentation graphics.</p>
          </div>

          <div className="container">
            <video width="auto" height="auto" controls>
              <source src={images+"Video.mp4"} type="video/mp4"/>
              Sorry, but your browser does not support this video content.
            </video>
          </div>

          <div className="container">
            <BrowserPreview scrollable={false} images={portfolioImages}/>
          </div>

        </section>



      </div>
    );
  }
}

export default withRouter(CommunityMarket);

import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/Unification/";

const portfolioImages = [
  {url:images+"1.png", caption:"A new, more powerful Promotion List enables users to find the promotions they need to manage more easily. "},
  {url:images+"6.PNG", caption:"A view selection component provides users with the ability to customize their view and save frequently accessed subsets of promotions.  "},
  {url:images+"5.PNG", caption:"Users can save their column and filter settings as a view.   "},
  {url:images+"2.png", caption:"The Programs List is consistent with the Promotions List for ease-of-use. "},
  {url:images+"3.png", caption:"A new Program Details screen provides information about a single Program in one location. It is also ready to support read-only users."},
  {url:images+"8.PNG", caption:"The New Promotion screen allows users to select a strategy to narrow down the required fields. Most of the old flows are consolidated within this screen. It also provides additional meta data to support Promotion List filtering & reporting."}
];

class Unification extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}
        <div className="white-background">
          <section className="container">
            <h1>Discount Management Vision</h1>
            <div className="date-container">
              <div className="date">2021</div>
              <div><Button className="jump-button" type="text" onClick={e => this.scrollToDesigns()}>Skip to the Visiontype</Button></div>
            </div>
          </section>
          <Image className="hero-image" src={images+"Hero.jpg"} preview={false} width="100%"/>
        </div>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Brief</h2>
            <div className="brief-content">
            <h4>Company</h4>
            <p><strong>The Home Depot</strong></p>

            <h4>Role</h4>
            <p><strong>Staff User Experience Designer</strong></p>

            <h4>Product</h4>
            <p><strong>Price Engine:</strong> A tool used to set discounts and condition-based price points for millions of Home Depot products.</p>

            <h4>Summary</h4>
            <p>The Price Engine product experienced multiple years of rapid feature development. Whole new flows were built to rapidly meet the business’ needs without consideration for how they fit into the larger product. I identified an opportunity to unify the Price Engine experience and, in doing so, reduce the technical debt. I lead the team to define a vision and establish a tactical strategy to achieve it. This is now a prioritized initiative. </p>

            </div>
          </section>
        </section>

        {/* BODY */}
        <section className="white-background">
          <div className="container">

            <div className="small-p">
              <h2>Details</h2>
              <h3>Background</h3>
              <p>Price Engine began as an application to allow users to create simple discounts based on a series of rules. As pricing strategies evolved, so did the required functionality to support them. The product team lacked a vision and was very reactive to new requests from the business. </p>

              <p>A new experience was created within Price Engine whenever a business request for functionality came in. Each of the new experiences address a different, specific pricing strategy. Additionally, Price Engine was built on top of an older proprietary application that is no longer supported. Since then, it has been fragmented into 3 code bases. Each code base uses its own design patterns, causing vast inconsistencies. </p>

              <h3>Opportunity</h3>
              <p>As I learned more about the system’s functionality and users’ needs, I noticed some patterns. The majority of pricing strategies’ entry forms had many of the same required fields. There are fields that are unique that would eventually be requested as part of another strategy as well. </p>
            </div>

            <Row gutter={[50,50]}>
              <Col span={12}>
                <figure className="side-image">
                  <Image src={images+"Slide5.jpeg"} preview={true}/>
                  <figcaption>A slide from the initiative pitch deck depicting the current state of the users, the strategies they manage, and the functionality they use to execute.</figcaption>
                </figure>
              </Col>
              <Col span={12}>
                <figure>
                  <Image src={images+"Slide8.jpeg"} preview={true}/>
                  <figcaption>A slide from the initiative pitch deck outlining user needs.</figcaption>
                </figure>
              </Col>
              <Col span={12}>
                <figure>
                  <Image src={images+"Slide11.jpeg"} preview={true}/>
                  <figcaption>A slide from the initiative pitch deck outlining specific users' needs in a matrix form.</figcaption>
                </figure>
              </Col>
              <Col span={12}>
                <figure>
                  <Image src={images+"Slide9.jpeg"} preview={true}/>
                  <figcaption>A slide from the initiative pitch deck outlining product team goals.</figcaption>
                </figure>
              </Col>
            </Row>

            <div className="small-p">
              <p>I started to map out the different experiences, what made them unique, and their similarities. There was more in common than not. I formed a hypothesis that all of the flows could be combined into a singular, configurable experience. This would provide consistency to the users who manage more than one strategy. It would also all features to all users, which would make experimentation more accessible. </p>

              <p>I shared this hypothesis with a peer on the team. We collaborated on a flow depicting what a new experience might look like. The proposed vision consolidated functionality and enhanced functionality to better support the growing user base. </p>

              <h3>Visiontype</h3>
              <p>Once we had a perspective, it was time to visualize it farther. I created a high-fidelity visiontype (a “vision prototype”) to help convey the unified Price Engine. I developed the prototype using Ant Design: an enterprise React pattern library used in one of the 3 codebases (and the one we selected to use in all new development). </p>

              <p>The visiontype focused on how the information architecture and user journeys would change. The details were not as important, such as the contents of table columns and form fields. Those would require additional research and user validation to get right. So, the Visiontype just uses some representative content to get the idea across. </p>

              <p>As I worked through the visiontype, I realized that my original hypothesis that we could consolidate everything into a singular experience was incorrect. In reality, we would only be able to go from 5 to 3, while also reducing the entry points from 6 to 4—still a big win for consistency and simplicity.</p>

              <h3>Implementation Strategy</h3>
              <p>I knew presenting such a drastic change to the product would make people think, “How do we get there?” Since this was not yet a prioritized initiative, I needed to propose a phased approach and obtain buy-in. So, I broke the vision into 4 phases with two workstreams: one focused on the discount list rewrite and the other focused on consolidating the discount creation experiences. </p>

              <h3>Socialization</h3>
              <p>I began socializing the vision with the lead product managers and engineers on the team. Showing them the visiontype helped to make the concept tangible. They were on-board with the vision and strategy. </p>
            </div>

            <figure>
              <Image src={images+"Phases.jpg"} preview={true}/>
              <figcaption>High-level user flow diagrams depicting the 4 phases for the creation experience consolidation workstream.</figcaption>
            </figure>

            <div className="small-p">
              <p>We knew we needed obtain leadership’s support for the initiative. So, we collaborated on a presentation presenting the problem, opportunity, visiontype, and strategy to get there. I presented the deck and visiontype to our management. They were sold on the vision, but were unsure how to get the initiative prioritized mid-year. </p>

              <h3>Prioritization</h3>
              <p>We discussed how we might achieve at least some of the vision through already prioritized initiatives. Working with the other leads, we modified the phases to better align to the existing roadmap. We also noted how this work would be a dependency in order to achieve some of the leadership team’s initiatives slated for later in the year. With this angle, we were able to get a prioritized initiative on the roadmap. </p>

              <h3>Outcome</h3>

              <p>As of this writing, 1 of 4 phases has been completed with another beginning shortly. </p>
            </div>
          </div>
        </section>

        <section id="designs" className="designs blue-background"  ref={node => this.designs.current = node}>
          <div className="container">
              <h2>Visiontype</h2>
              <p>A high-fidelity prototype meant to communicate key concepts of the vision.</p>
          </div>

          <div className="container">
            <BrowserPreview scrollable={true} images={portfolioImages}/>
          </div>

        </section>



      </div>
    );
  }
}

export default withRouter(Unification);

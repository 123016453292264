import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/StreamOne/";

const portfolioImages = [
  {url:images+"1.jpg", caption:"A Cloud Subscription Configuration page within the checkout flow. Users need to be able to create an account or associate the subscription with an existing one."},
  {url:images+"2.jpg", caption:"A revised Order Summary page allows users to review their order before submitting it."}
];

class StreamOne extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}

        <section id="designs" className="designs blue-background"  ref={node => this.designs.current = node}>
          <h1>StreamOne Checkout Refresh</h1>
          <div className="date-container">
            <div className="date">2016</div>
          </div>
          <div className="container">
            <BrowserPreview scrollable={true} images={portfolioImages}/>
          </div>
        </section>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Context</h2>
            <div className="brief-content">
              <h4>Company</h4>
              <p><strong>Tech Data Corporation:</strong> Tech Data is a global IT distributor serving over 125,000 resellers worldwide. At the time, it had revenues of $36+ billion and was the largest public company in Florida—a Fortune 100 company.</p>

              <h4>Role</h4>
              <p><strong>UX Designer &amp; Developer</strong></p>

              <h4>Product</h4>
              <p><strong>StreamOne:</strong> Tech Data’s global marketplace and management platform for subscription cloud products serving 20+ countries</p>

              <h4>Users</h4>
              <p><strong>Cloud Resellers:</strong> purchasers for companies that resell cloud subscriptions and services</p>

            </div>
          </section>
        </section>

        {/* BODY */}
        <section className="white-background">
          <div className="container">
          <figure className="side-image">
            <Image src={images+"OldCheckout.jpg"} preview={true}/>
            <figcaption>The original, single-page experience. My design replaced this with an encapsulated cart concept that provided a more intuitive experience.</figcaption>
          </figure>
            <div className="small-p">
              <h2>Details</h2>
              <h3>Background</h3>
              <p>Tech Data’s B2B platform for selling and managing cloud subscriptions and services across 18 countries had a complex and inconsistent checkout process. Buying cloud products requires varied functionality dependent on the products in the cart. The previous checkout was confusing and error-prone due to exposure of internal language/terminology, inconsistent layout/styling, conflicting design patterns with other corporate experiences, and unhelpful error messaging.</p>
              <p>I improved the user experience by breaking out the steps of checkout into consistent and navigable pages and modules. The user is now able to clearly move from one step to the next. I also made the experience more consistent with Shop, StreamOne’s sister e-commerce platform, by incorporating design patterns and visual styling (the start of a larger effort to slowly converge the experiences).</p>
            </div>
            <div className="small-p">
              <h3>Results</h3>
            </div>
            <Quote>Clean &amp; efficient!</Quote>
            <div className="small-p">
              <p>Users responded positively to the new checkout experience giving it a 4.4 of 5 rating when surveyed with the majority saying it exceeded the competition’s checkout experiences. “Clean & efficient!” a user commented. This interaction framework has been extended to accommodate more vendors and additional complexity. These changes were cited by Microsoft as a vast improvement to the purchasing experience and has lead to increased partnership with Tech Data.</p>
            </div>
            <div className="small-p">
              <h3>Personal Lessons &amp; Areas for Improvement</h3>
              <p>Before implementing such a change, get a benchmark for user satisfaction to be able to compare post-launch. Work with the development team and product management to set clear delivery expectations upfront to avoid too much back-and forth.</p>
            </div>
          </div>
        </section>


      </div>
    );
  }
}

export default withRouter(StreamOne);

import React from 'react';
import { Image, Tooltip } from 'antd';
import { withRouter} from 'react-router-dom';
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import './App.css';

class BrowserPreview extends React.Component {
  constructor(props) {
     super(props);

     this.state = {
       current:0
     };

     this.images_mask = React.createRef();

     this.handleKeyNav = this.handleKeyNav.bind(this);
     document.onkeydown = (event) => this.handleKeyNav(event);

     this.xDown = null;
     this.yDown = null;

     this.handleTouchStart = this.handleTouchStart.bind(this);
     this.handleTouchMove = this.handleTouchMove.bind(this);
     //this.images_mask.current.addEventListener('touchstart', this.handleTouchStart, false);
     //this.images_mask.current.addEventListener('touchmove', this.handleTouchMove, false);
  }


  componentWillUnmount() {
    document.onkeyDown = null;
  }



  handleKeyNav = (event) =>{
       switch (event.keyCode) {
          case 37:
               this.previous();
             break;
          case 39:
               this.next();
             break;
       }
  }

  previous = () =>{
    var i = this.state.current;
    i--;
    if(i>=0){
      this.setState({
        current: i
      });
      this.images_mask.current.scrollTo(0, 0);
    }
  }

  next = () =>{
    var i = this.state.current;
    i++;
    if(i<=this.props.images.length - 1){
      this.setState({
        current: i
      });
      this.images_mask.current.scrollTo(0, 0);
    }
  }

  jumpTo = (e) =>{
    var index = e.target.getAttribute("index");
    this.setState({
      current: index
    });
    this.images_mask.current.scrollTo(0, 0);
  }


  getTouches = (evt) => {
    return evt.touches ||             // browser API
           evt.originalEvent.touches; // jQuery
  }

  handleTouchStart = (evt) => {
    const firstTouch = this.getTouches(evt)[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  };

  handleTouchMove = (evt) => {
    if ( ! this.xDown || ! this.yDown ) {
        return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.xDown - xUp;
    var yDiff = this.yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
            /* left swipe */
            this.next();
        } else {
            /* right swipe */
            this.previous();
        }
    }/* else {
        if ( yDiff > 0 ) {
            // up swipe
        } else {
            // down swipe
        }
    }*/
    /* reset values */
    this.xDown = null;
    this.yDown = null;
};





  render(){
    return(
      <div className={"browser-preview "+(this.props.direction ? this.props.direction : "horizontal")} onTouchStart={(e) => this.handleTouchStart(e)} onTouchMove={(e) => this.handleTouchMove(e)}>
        <div className="browser-preview-container">
          <div className={this.state.current != 0 ? "previous-button active":"previous-button"} onClick={e => this.previous()}><Tooltip title="Click or use the left arrow key"><LeftOutlined/></Tooltip></div>
          <div className={this.props.scrollable === false ? "device" : "device scrollable"}>
            <div className="images-mask" ref={node => this.images_mask.current = node}>
              <div className="images">
              {this.props.images.map((image, index) => (
                <div key={index} className={this.state.current == index ? "image active":"image"}>
                  <Image src={image.url} preview={false}/>
                </div>
              ))}
              </div>
            </div>
          </div>
          <div className={this.state.current != (this.props.images.length - 1) ? "next-button active":"next-button"} onClick={e => this.next()}><Tooltip title="Click or use the right arrow key"><RightOutlined/></Tooltip></div>
        </div>
        {this.props.images.length > 1 &&
          <div className="dots">
          {this.props.images.map((image, index) => (
            <div index={index} key={index} className={this.state.current == index ? "dot active":"dot"} onClick={e => this.jumpTo(e)}></div>
          ))}
          </div>
        }
        <div className="captions">
        {this.props.images.map((image, index) => (
          <div key={index} className={this.state.current == index ? "caption active":"caption"}>
            {image.caption}
          </div>
        ))}
        </div>
      </div>
    );
  }
}

export default withRouter(BrowserPreview);

import React from 'react';
import { Layout, Row, Col, Space, Card, Avatar, Tag, Drawer, Button, Modal, Alert, Form, Input, message } from 'antd';
import { withRouter} from 'react-router-dom';
import queryString from 'query-string';

import Lpm from './portfolio/Lpm';
import Order from './portfolio/Order';
import StreamOne from './portfolio/StreamOne';
import Sony from './portfolio/Sony';
import TDHeader from './portfolio/TDHeader';
import Tiglff from './portfolio/TIGLFF';
import CommunityMarket from './portfolio/CommunityMarket';
import Hyperloop from './portfolio/Hyperloop';
import Unification from './portfolio/Unification';
import Manual from './portfolio/Manual';

import {ArrowLeftOutlined, MailOutlined, LinkedinOutlined, LockOutlined} from '@ant-design/icons';
import './App.css';


import * as portfolioData from './portfolio/portfolio.json';

const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;

const portfolio = portfolioData.default;
const PWD = "vision" //Yes, this is lazy...but it isn't Fort Knox

class App extends React.Component {

  constructor(props) {
     super(props);

     var authenticated = false;
     let params = queryString.parse(this.props.location.search);
     if(localStorage.getItem('authenticated') === "true" || params.auth === PWD){
       authenticated = true;
       localStorage.setItem('authenticated', true);
     }

     this.state = {
       showDrawer: false,
       currentPortfolio: '',
       authenticated:authenticated,
       requestAuthentication: false,
       failedPassword: false
     };

     this.password = React.createRef();
  }

  showPortfolio = (portfolio) =>{
    console.log(portfolio);

    if(portfolio.locked && !this.state.authenticated){
      this.setState({
        requestAuthentication: true,
        currentPortfolio: portfolio.key
      });

    }else{
      this.setState({
        showDrawer: true,
        currentPortfolio: portfolio.key
      });
    }


  };

  getPortfolio = () => {
    switch(this.state.currentPortfolio){
      case 'LPM': return <Lpm/>;
      case 'Order': return <Order/>;
      case 'TDHeader': return <TDHeader/>;
      case 'StreamOne': return <StreamOne/>;
      case 'Sony': return <Sony/>;
      case 'TIGLFF': return <Tiglff/>;
      case 'CommunityMarket': return <CommunityMarket/>;
      case 'Hyperloop': return <Hyperloop/>;
      case 'Unification': return <Unification/>;
      case 'Manual': return <Manual/>;
      default: return null;
    }
  }

  authenticate = () => {
    if(this.password.props.value === PWD){ //Yes, this is lazy...but it isn't Fort Knox
      this.setState({
        showDrawer: true,
        requestAuthentication: false,
        authenticated: true
      });
      localStorage.setItem('authenticated', true);
      message.success('Private portfolio pieces are unlocked.');
    }else{
      this.setState({
        failedPassword: true
      });
    }
  }

  handleModalCancel = () => {
    this.setState({
      requestAuthentication: false,
      failedPassword: false
    });
  }

  render(){
    return(
      <div className="App">
        <Layout>
          <Content>
            <section id="hero">
              <div id="hero-center">
                <div id="hero-text" className="container">
                  <div id="logo" className="container"><span id="name">Christian Reich</span></div>
                  <h2>
                    <span id="hero-left-text">
                      <span>Enterprise </span>
                      <span id="strike">UX Designer </span>
                    </span>
                    <span id="visionary">Visionary</span>
                  </h2>
                  <h3>I solve complex problems with design, technology, and a solid game plan.</h3>
                </div>
              </div>
            </section>

            <section id="about" className="container">
              <h2 id="big-about-text"><span id="design">Design</span>ed<br/> For Big Business</h2>
              <div id="about-content">
                <p>I lead teams through enterprise complexity. Together, we address both user needs and business goals. <span className="specialty">My specialty is creating an experience vision with a realistic plan to get there.</span> 8+ years of experience in UX design enable me to quickly deliver intuitive solutions that customers love.</p>
                <p>Do you have goals and a whiteboard? Let’s collaborate! A ton of constraints, you say? …even better.</p>
              </div>
            </section>

            <section id="portfolio">
              <div className="container">
                <h2>My Work</h2>
                <Row gutter={[24,24]} justify="center">

                  {portfolio.map(obj => ( !obj.hide &&
                    <Col span={24} md={12} lg={8} key={obj.key}>
                      <Card className="portfolio-card" bordered={false} hoverable style={{ width: '100%' }} cover={ <img src={"img/"+obj.image} />} onClick={e => {this.showPortfolio(obj)}}>
                        {obj.tag ? <Tag color="#FCC72E">{obj.tag}</Tag> : false }
                        <div><span className="company">{obj.company}</span> <span className="date">{obj.date}</span></div>
                        <h3 className="title">{(obj.locked && !this.state.authenticated) ? <LockOutlined />:""}{obj.title}</h3>
                      </Card>
                    </Col>
                  ))}

                </Row>
              </div>
            </section>

            <section id="info" className="container">
              <Row gutter={0}>
                <Col id="info-col-1" order={1} span={24} lg={{'span':16, 'order':0}}>
                  <h2>Hey There!</h2>
                  <p>I am currently a User Experience Principal for The Home Depot. In this role I am responsible for the end-to-end Pro customer experience. I love working as part of this large enterprise user experience team.</p>
                  <p>I conduct research, create interactive prototypes, and communicate effectively with all levels of the organization. As a leader, I have managed UXers &amp; PMs and converted partners from UX-hostile to UX advocates. My product experience ranges from global B2B e-commerce sites to a mobile ticketing app for a local film festival (designed & developed).</p>
                  <p>I am a strategic partner who uses design to impact change—not a pixel-pusher. My entrepreneurial spirit drives me to create things that are both practical and exciting. A multi-disciplinary background—combining design, technology, and business—helps me get the job done.</p>
                  <p>Let’s talk about how we can solve big business problems together!</p>
                  <p>–Christian Reich</p>
                  <Space direction="horizontal" wrap>
                    <Button type="primary" icon={<MailOutlined />} href="mailto:christian@ux-unicorn.com" target="_blank">Email Me</Button>
                    <Button type="secondary" icon={<LinkedinOutlined />} href="https://www.linkedin.com/in/ux-strategy/" target="_blank">Connect on LinkedIn</Button>
                  </Space>
                </Col>
                <Col id="info-col-2" order={0} span={24} lg={{'span':8, 'order':1}}>
                </Col>
              </Row>
            </section>

          </Content>

          <Footer>© Christian Reich, 2022. Designed &amp; Developed by Christian Reich</Footer>
        </Layout>

        <Drawer title={<div className="logo"></div>} id="portfolio-drawer" width="100%" closeIcon={<span><ArrowLeftOutlined /> Back</span>} placement="right" closable={true} onClose={e => {this.setState({showDrawer: false});}} visible={this.state.showDrawer} destroyOnClose={true}>
            {this.getPortfolio()}
        </Drawer>

        <Modal title={<span><LockOutlined /> Password Protected</span>} okText="Unlock" visible={this.state.requestAuthentication} onOk={this.authenticate} onCancel={this.handleModalCancel}>
        <p>Some portfolio pieces are password protected. Please enter the password or <a href="mailto:christian@ux-unicorn.com" target="_blank">contact me</a> to obtain the password. You'll just have to do this once in this browser.</p>
        <Space direction="vertical" style={{width:"100%"}}>
          {this.state.failedPassword &&
            <Alert message="The password you entered is incorrect." type="error" />
          }
          <Form>
            <Form.Item label="Password" name="password" value={this.state.password}>
              <Input.Password ref={(input) => { this.password=input; if(input){input.focus();} }} onPressEnter={this.authenticate} focus={true} />
            </Form.Item>
          </Form>
        </Space>
      </Modal>

      </div>
    );
  }
}

export default withRouter(App);

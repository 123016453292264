import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import MobileBrowserPreview from "../MobileBrowserPreview"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/Hyperloop/";

const portfolioImages = [
  {url:images+"Slide1.jpeg", caption:false},
  {url:images+"Slide2.jpeg", caption:false},
  {url:images+"Slide3.jpeg", caption:"I interviewed a friend who travels often for work. While I would have liked more perspectives, I wanted to spend more time on exploring designs with the assumption that he is representative of the business traveler."},
  {url:images+"Slide4.jpeg", caption:"After interviewing, I produced assumptions to help define my scope."},
  {url:images+"Slide5.jpeg", caption:"I then conducted industry research on travel app experiences."},
  {url:images+"Slide6.jpeg", caption:"I then conducted industry research on travel app experiences."},
  {url:images+"Slide7.jpeg", caption:"I then conducted industry research on travel app experiences."},
  {url:images+"Slide8.jpeg", caption:"I then conducted industry research on travel app experiences."},
  {url:images+"Slide9.jpeg", caption:"I brainstormed possible functionality and prioritized based on assumptions and research findings using the MoSCoW method."},
  {url:images+"Slide10.jpeg", caption:"I brainstormed possible functionality and prioritized based on assumptions and research findings using the MoSCoW method."},
  {url:images+"Slide11.jpeg", caption:"From there, I was able to define which screens would be needed and prioritize those."},
  {url:images+"Slide12.jpeg", caption:"I sketched out some design concepts."},
  {url:images+"Slide13.jpeg", caption:"Sketches exploring components for the home screen"},
  {url:images+"Slide14.jpeg", caption:"Home screen sketches"},
  {url:images+"Slide15.jpeg", caption:"Sketches of the home screen and search result listings"},
  {url:images+"Slide16.jpeg", caption:"Sketches of possible booking screens and a ticket screen"},
  {url:images+"Slide17.jpeg", caption:"I then worked on some digital wireframes and conducted some user testing with my friend."},
  {url:images+"Slide18.jpeg", caption:"Iterations on the home screen design after user testing"},
  {url:images+"Slide19.jpeg", caption:"Iterations on the search results design after user testing"},
  {url:images+"Slide20.jpeg", caption:"Iterations on the ticket purchase screen design after user testing"},
  {url:images+"Slide21.jpeg", caption:"Iterations on the ticket screen design after user testing"},
  {url:images+"Slide22.jpeg", caption:"Iterations on the unsupported destination screen design before after user testing and an untested navigation interface"},
  {url:images+"Slide23.jpeg", caption:"I took the landing screen of the proposed app experience and created 4 visual design options. I based the visual design on the Virgin Hyperloop's website."},
  {url:images+"Slide24.jpeg", caption:"I took the landing screen of the proposed app experience and created 4 visual design options. I based the visual design on the Virgin Hyperloop's website."},
  {url:images+"Slide25.jpeg", caption:"4 visual design options with minor functionality differences"},
  {url:images+"Slide26.jpeg", caption:"The final visual design for the home page"},
];

class Hyperloop extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}

        <div className="white-background">
          <section className="container">
            <h1>Hyperloop App Exploration</h1>
            <div className="date-container">
              <div className="date">2018</div>
              <div><Button className="jump-button" type="text" onClick={e => this.scrollToDesigns()}>Skip to the Final Designs</Button></div>
            </div>
          </section>
            <Image className="hero-image" src={images+"hero.jpg"} preview={false} width="100%"/>
        </div>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Context</h2>
            <div className="brief-content one-col">
              <h4>Design Challenge</h4>
              <p>This is a personal exercise responding to a design challenge: “Elon Musk has successfully completed construction of the <a href="https://en.wikipedia.org/wiki/Hyperloop" target="_blank">Hyperloop</a>. Design an interface that allows passengers to purchase tickets to travel across the U.S.” This exercise was time-boxed to 3 days (after work) and had built-in constraints.</p>
            </div>
          </section>
        </section>

        {/* BODY */}
        <section id="designs" className="designs blue-background">
          <h2>Presentation</h2>
          <p>The following no-frills presentation walks through my process for creating a mobile app targeted at Hyperloop travelers.</p>
          <BrowserPreview scrollable={false} direction="horizontal" images={portfolioImages}/>
        </section>


      </div>
    );
  }
}

export default withRouter(Hyperloop);

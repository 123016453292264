import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/Header/";

const portfolioImages = [
  {url:images+"2.jpg", caption:"The new global header design supporting resellers’ need to shop, explore services, and obtain helpful resources. "}
];

class TDHeader extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}

        <section id="designs" className="designs white-background"  ref={node => this.designs.current = node}>
          <h1>Global E-Commerce Header</h1>
          <div className="date-container">
            <div className="date">2018</div>
          </div>
          <Image className="hero-image" src={images+"Hero.jpg"} preview={false} width="100%"/>
        </section>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Context</h2>
            <div className="brief-content">
              <h4>Company</h4>
              <p><strong>Tech Data Corporation:</strong> Tech Data is a global IT distributor serving over 125,000 resellers worldwide. At the time, it had revenues of $36+ billion and was the largest public company in Florida—a Fortune 100 company.</p>

              <h4>Role</h4>
              <p><strong>Global User Experience Manager</strong></p>

              <h4>Product</h4>
              <p><strong>Shop:</strong> Tech Data’s global e-commerce site selling IT products and services</p>

              <h4>Users</h4>
              <p><strong>Resellers:</strong> multiple roles from small, medium, and large companies who are researching or purchasing products and services to purchase</p>

            </div>
          </section>
        </section>

        {/* BODY */}
        <section className="white-background">
          <div className="container">
          <figure className="side-image">
            <Image src={images+"Old.jpg"} preview={true}/>
            <figcaption>The existing American header posed many experience challenges and didn't support localization.</figcaption>
          </figure>
            <div className="small-p">
              <h2>Details</h2>
              <h3>Background</h3>
              <p>To support the global expansion of Tech Data’s e-commerce website, a new header needed to be designed. The existing header was poorly organized, bulky, and would not lend well to localization due to the spacing of elements and fixed positioning. I collaborated with other designers to create a new header that would support Tech Data’s new market strategy and global users.</p>
              <h3>Problem</h3>
              <p>Tech Data was taking its business-to-business website global, starting in Indonesia. The existing American header had many challenges:</p>
              <ul>
                <li>Not built to support localization</li>
                <li>A mix of company information and e-commerce functionality</li>
                <li>Difficult to navigate due to the poor information architecture</li>
                <li>Too bulky</li>
                <li>Misuse of valuable real estate</li>
                <li>Hard to find support</li>
              </ul>
              <p>The new market offered an opportunity to start over as the site was being re-written.</p>
              <p>Note: the focus of this activity was the layout of the header. The information architecture of the navigation was slated as a follow-up activity.</p>
              <h3>Process</h3>
              <p>I took an inventory of all the items in the current header and attempted to find a more natural grouping of them. A previous activity involving customer research produced a potential information architecture for the site navigation. This was my starting point. A clear trend appeared. There were three types of information that needed to be addressed in the header:</p>
              <ul>
                <li>Cross-site and corporate content</li>
                <li>Product finding</li>
                <li>Site-specific tools</li>
              </ul>
            </div>
            <figure>
              <Image src={images+"Exploration.jpg"} preview={false}/>
              <figcaption>An exploration of a slim header that offers new functionality and global flexibility.</figcaption>
            </figure>
            <div className="small-p">
              <p>I started with a thin two-tiered design. This exploration exposed the top categories of the product catalog, minimized the links to the tools and resources, and provided clear methods to get assistance. This exploration was scrapped because users needed easier access to the tools they used all day long and the ability to find corporate information and other Tech Data sites was unclear.</p>
              <p>Since there were three main types of information with a clear hierarchy, I explored a three-tiered approach. The first tier is focused on cross-site and corporate content. Since a user’s account information is shared across Tech Data websites, it made sense to include it in the corporate ribbon. I planned that in the future, there would be additional links near the About link.</p>
            </div>
            <figure className="side-image">
              <Image src={images+"1.jpg"} preview={true}/>
              <figcaption>The final header shown in context on the search page. This header focuses on informational hierarchy and making use of the space.</figcaption>
            </figure>
            <div className="small-p">
              <p>The next section with the logo focuses on finding products. This time, high-level groupings replace the long list of categories. The search bar allows users to find products and toggle their search experience to find orders and other types of items.</p>
              <p>The final section introduces site-specific information and resources in a tool bar. This tool bar is dynamic and will eventually allow users to bookmark their most commonly used tools. The resources, help items, and cart made sense here because they are site-specific.</p>
              <p>The layout of the header allows room for growth, more clear organization, and scalability to support increased word lengths caused by localization.</p>
              <p>I took a version of this design to a customer event where I interviewed users about their experience with the current header and gathered feedback on the proposed design. The design was well received with some suggested changes to terminology.</p>
              <p>The most recent design shown is the result of a collaboration with two additional designers and is based on my original version.</p>

              <h3>Possible Next Steps</h3>
              <p>More focus should be given to the information architecture of the tool bar and corporate navigations. The design must be more rigorously user tested by representative global users. I left the company before the header was re-written.</p>
            </div>

          </div>
        </section>

        <section className="blue-background">
          <div className="container">
            <BrowserPreview scrollable={false} images={portfolioImages}/>
          </div>
        </section>



      </div>
    );
  }
}

export default withRouter(TDHeader);

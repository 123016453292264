import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import MobileBrowserPreview from "../MobileBrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/TIGLFF/";

const portfolioImages = [
  {url:images+"1.png", caption:false},
  {url:images+"2.png", caption:false},
  {url:images+"3.png", caption:false}
];

class Tiglff extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}

        <section id="designs" className="designs blue-background"  ref={node => this.designs.current = node}>
          <h1>Film Festival Ticketing App</h1>
          <div className="date-container">
            <div className="date">2013</div>
          </div>
          <MobileBrowserPreview images={portfolioImages}/>
        </section>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Context</h2>
            <div className="brief-content">
              <h4>Company</h4>
              <p><strong>Tampa International Gay &amp; Lesbian Film Festival</strong></p>

              <h4>Role</h4>
              <p><strong>Intern</strong></p>
            </div>
          </section>
        </section>

        {/* BODY */}
        <section className="white-background">
          <div className="container">
            <div className="small-p">
              <h2>Details</h2>
              <p>As an intern, and later as a board member, I created and maintained a cross-platform mobile application for the film festival allowing users to browse films, buy and use tickets & passes, build their own calendar, and rate the films. In addition, I designed and developed a digital ticketing system to provide the festival with actionable attendance data. The data is used to refine ticket, pass, and package offers to save the festival money, as well as provide a more accurate attendance count</p>
              <p>When I began the design phase, I based the initial design on much of the design patterns I had seen in other event apps: a home page with a grouping of icons leading to other areas of the site. I pursued this design but realized this was not a supportive user experience. The majority of the time, users wish to browse the film schedule. So I decided to make the home page a listing of the films. Due to the visual nature of film, I decided to highlight featured images as the imagery often entices festival-goers more than the title.</p>
              <p>I needed to find a way to show the title, time, and location without taking up too much space on the screen, while still giving justice to the featured images. I decided to implement a responsive scroll that would reveal the title of the film as an overlay only when the cell was in a certain zone of the screen. This allowed users to focus on one film at a time and gave the full featured images justice on small screens.</p>
              <p>Interactive features, like the ability to filter films based on different attributes, build your own festival schedule, rate films after viewing, and purchase tickets in-app enhance the user experience. The “On Location” feature showed a welcome screen when the app detected the user was at the festival. This provided quick access to the next film’s information and digital tickets.</p>
              <p>The app was originally for iOS only and was re-written to be an HTML5, cross-platform application in its second year. I have revised the app over time to make changes based on user feedback and usage analytics.</p>
              <p>The ticketing platform runs using a custom PHP plugin for WordPress—the basis for the festival site. A separate cross-platform ticket scanning app is used for collecting attendance data. The system can read QR codes and barcodes on tickets to scan them. The site database acts as the source of truth and each ticket or pass is validated in real time.</p>
              <p>The scanning app provides a simple interface for the many volunteers to use. Based on the time, the film to scan for will be auto-selected. Simply hold the device’s camera over the code to scan and the screen will turn green if valid or red with an informative error if the ticket is invalid. The app also has features to transfer tickets, lookup attendance history, and search for ticket data in case someone forgot their ticket.</p>
              <h3>Areas for Improvement</h3>
              <ul>
              <li>The app could use a more intuitive and traditional navigation pattern</li>
              <li>The font size and weight could be increased to support the older audience</li>
              <li>Purchasing&nbsp;tickets could be more intuitive with better association between tickets and films</li>
              <li>Checkout should support Apple Pay for quick checkout</li>
              <li>The responsive scroll should be tested to ensure it properly supports user needs</li>
              </ul>
            </div>

          </div>
        </section>


      </div>
    );
  }
}

export default withRouter(Tiglff);

import React from 'react';
import { Layout, Row, Col, Space, Image, Tag, Divider, Button } from 'antd';
import { withRouter} from 'react-router-dom';
import {ArrowLeftOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import Quote from "../Quote"
import BrowserPreview from "../BrowserPreview"
import '../App.css';

const { Header, Footer, Sider, Content } = Layout;

const images = "../img/Sony/";

const portfolioImages = [
  {url:images+"1.jpg", caption:""},
  {url:images+"2.jpg", caption:""},
  {url:images+"3.jpg", caption:""}
];

class Sony extends React.Component {

  constructor(props) {
     super(props);

     this.state = {

     };

     this.portfolio = React.createRef();
     this.designs = React.createRef();
  }

  scrollToDesigns = () =>{
    var rect = this.designs.current.getBoundingClientRect();
    this.portfolio.current.parentElement.scrollTo({
      top: (rect.top - 100),
      left: 0,
      behavior: 'smooth'
    });
  }

  render(){
    return(
      <div className="portfolio"  ref={node => this.portfolio.current = node}>
        {/* OVERVIEW */}

        <section id="designs" className="designs blue-background"  ref={node => this.designs.current = node}>
          <h1>Sony ExpressNet Proposal</h1>
          <div className="date-container">
            <div className="date">2015</div>
            <div><Button className="jump-button" type="text" onClick={e => this.scrollToDesigns()}>Skip to the Final Designs</Button></div>
          </div>
          <div className="container">
            <BrowserPreview scrollable={true} images={portfolioImages}/>
          </div>
        </section>

        <section className="container">
          <section className="brief">
            <h2 className="brief-title">Context</h2>
            <div className="brief-content">
              <h4>Company</h4>
              <p><strong>Tech Data Corporation:</strong> Tech Data is a global IT distributor serving over 125,000 resellers worldwide. At the time, it had revenues of $36+ billion and was the largest public company in Florida—a Fortune 100 company.</p>

              <h4>Role</h4>
              <p><strong>Associate Application Developer</strong></p>

              <h4>Product</h4>
              <p><strong>Sony ExpressNet:</strong> a white-labeled e-commerce site selling Sony products to authorized resellers</p>

              <h4>Users</h4>
              <p><strong>Sony Resellers:</strong> companies that resell Sony products to end-customers</p>

            </div>
          </section>
        </section>

        {/* BODY */}
        <section className="white-background">
          <div className="container">
            <div className="small-p">
              <h2>Details</h2>
              <p>Sony, a vendor-partner of Tech Data, requested a redesign of their white-labeled reseller e-commerce site. They wanted a more modern interface with more robust order tracking capabilities. This design was a concept presented to Sony during early negotiations. The project was eventually deprioritized. </p>
            </div>
          </div>
        </section>


      </div>
    );
  }
}

export default withRouter(Sony);
